<template>
  <div class="update-price-form">
    <el-form :model="updatePriceConfig.data" :rules="updatePriceConfig.rules"
             :ref="updatePriceConfig.formName">
      <el-form-item label="当前售价" prop="salePrice">
        <el-input v-model="updatePriceConfig.data.salePrice">
          <div slot="append">
            RMB (单位:元)
          </div>
        </el-input>
        <el-tooltip class="el-input-question" effect="dark" content="点击查看佣金比例" placement="top">
          <i @click="profitRateDialogConfig.showDialog()" class="el-icon-question"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="原价" prop="realPrice">
        <el-input v-model="updatePriceConfig.data.realPrice">
          <div slot="append">
            RMB (单位:元)
          </div>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary"
                   @click="updatePriceConfig.onSubmitClick">提交修改
        </el-button>
      </el-form-item>
    </el-form>
    <el-dialog
      center
      title="佣金比例"
      :visible.sync="profitRateDialogConfig.showFlag"
      :append-to-body="true"
    >
      <profit-rate></profit-rate>
    </el-dialog>
  </div>
</template>

<script>
import {goods} from "@/apis/index";
import validator from "@/resources/js/async-validator";

export default {
  name: "updatePrice",
  components: {
    ProfitRate: () => import('@PAGE/common/ProfitRate'),
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    model: {
      type: Object
    },
  },
  created() {
    this.setDefaultValue();
  },
  watch: {
    'updatePriceConfig.data.salePrice': {
      handler() {
        this.checkForm();
      },
    },
    'updatePriceConfig.data.realPrice': {
      handler() {
        this.checkForm();
      },
    },
    model: {
      handler(val) {
        if (val) {
          this.setDefaultValue();
        }
      },
    }
  },
  data() {
    return {
      updatePriceConfig: {
        formName: 'updatePriceForm',
        data: {
          salePrice: null,
          realPrice: null,
        },
        rules: {
          salePrice: [
            {
              required: true,
              message: '售价不能为空',
              trigger: ['blur', 'change']
            },
            {
              validator: validator.validMoney,
              message: '金额数值不合法'
            },
            {
              validator: (rule, value, callback) => {
                let {realPrice} = this.updatePriceConfig.data;
                realPrice = parseFloat(realPrice);
                value = parseFloat(value);
                if ((realPrice || realPrice === 0) && realPrice < value) {
                  callback(new Error(rule.message))
                } else {
                  callback()
                }
              },
              message: '售价不能大于原价'
            },
            {
              validator: (rule, value, callback) => {
                if (value) {
                  const price = parseFloat(value);
                  if (price < 0.00 || price > 1000000.00) {
                    callback(new Error('金额范围0.00 ~ 1000000.00'))
                  } else {
                    callback()
                  }
                } else {
                  callback()
                }
              }
            }
          ],
          realPrice: [
            {
              validator: (rule, value, callback) => {
                if (value) {
                  validator.validMoney(rule, value, callback)
                } else {
                  callback()
                }
              },
              message: '金额数值不合法'
            },
            {
              validator: (rule, value, callback) => {
                let {salePrice} = this.updatePriceConfig.data;
                salePrice = parseFloat(salePrice);
                value = parseFloat(value);
                if (value && salePrice && salePrice > value) {
                  callback(new Error(rule.message))
                } else {
                  callback()
                }
              },
              message: '原价不能小于售价'
            },
            {
              validator: (rule, value, callback) => {
                if (value) {
                  const price = parseFloat(value);
                  if (price < 0.00 || price > 1000000.00) {
                    callback(new Error('金额范围0.00 ~ 1000000.00'))
                  } else {
                    callback()
                  }
                } else {
                  callback()
                }
              }
            }
          ],
        },
        onSubmitClick: () => {
          this.$refs[this.updatePriceConfig.formName].validate()
          .then(() => {
            let {realPrice, salePrice} = this.updatePriceConfig.data;
            goods.updatePrice({
              id: this.id,
              type: this.type,
              realPrice: realPrice,
              salePrice: salePrice,
            }).then(() => {
              this.$refs[this.updatePriceConfig.formName].resetFields();
              this.$message.success('保存成功！');
              this.$emit('success');
            })
          });
        },
      },
      profitRateDialogConfig: {
        showFlag: false,
        showDialog: () => {
          this.profitRateDialogConfig.showFlag = true;
        },
      },
    }
  },
  methods: {
    checkForm() {
      this.$refs[this.updatePriceConfig.formName].validateField(['salePrice', 'realPrice']);
    },
    setDefaultValue() {
      if (this.model) {
        this.updatePriceConfig.data.salePrice = this.model.salePrice || null;
        this.updatePriceConfig.data.realPrice = this.model.realPrice || null;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.update-price-form {
  ::v-deep {
    .el-form-item {
      padding-right: 20px;
    }

    .el-form-item {
      .el-input-question {
        position: absolute;
        right: -25px;
        top: 52px;
        cursor: pointer;
      }
    }
  }
}
</style>
